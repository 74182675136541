<template>
  <!--
    Used to display the configuration(s) of the relevant device.
  -->
  <Portlet
    :title="title"
    icon="cog"
    class="actionCamDetailConfiguration mt-4"
  >
    <template v-if="data">
      <pre>
              <template v-for="(item, index) in data">
                <span
                  :key="`exif-lineSpan-${ index }`"
                  :class="colorClassOfLine(item)"
                >{{ item }}</span>
              </template>
            </pre>
    </template>
    <p v-else>
      {{ $t('noDataAvailable') }}
    </p>
  </Portlet>
</template>

<script>
export default {
  name: 'DeviceDetailExifAndConfiguration',
  props: {
    installationId: {
      type: String,
      required: true
    },
    deviceId: {
      type: String,
      required: true
    },
    isExif: {
      type: Boolean
    },
    exifData: {
      type: Array
    },
    confData: {
      type: Array
    },
    title: {
      type: String
    }
  },
  data () {
    return {
      data: []
    }
  },
  watch: { 
    exifData (arr) {
      this.data = arr;
    },
    confData (arr) {
      this.data = arr;
    }
  },
  created () {
    if(this.isExif) {
      this.data = this.exifData;
    } else {
      this.data = this.confData;
    }
  },
  methods: {
    colorClassOfLine (line) {
      let trimmedLine = line.trim();
      if (!trimmedLine) {
        return 'd-none';
      }
      if (!trimmedLine.length) {
        return 'd-none';
      }
      if (trimmedLine.length == 0) {
        return 'd-none';
      }
      if (trimmedLine.charAt(0) === '#') {
        return 'lighterGray'
      }
    }
  }
}
</script>

<style scoped>
.exif-row {
  display: block;
  min-height: 17px;
}
pre {
  white-space: normal;
}
span {
  display: block;
  min-height: 17px;
}
</style>
